import { activityPageMembersLimit } from './appConstants';
import { getQueryString } from '../utils/utilityFunctions';
import { pageSize } from './paginationConstants';

export const getMembersApiUrl = (communityId, filters) =>
  `member/v4?communityId=${communityId}&${filters}`;

export const getMembersApiV5Url = (filters) => `member/v5?${filters}`;

export const getBulkDMMemberUserIdCount = (communityId, provider, extraParam, extraParamType) => {
  if (extraParamType === 'criteria') {
    return `community/${communityId}/dm-member-count?criteria=${extraParam}&provider=${provider}`;
  } else if (extraParamType === 'memberIds') {
    return `community/${communityId}/dm-member-count?memberIds=${extraParam}&provider=${provider}`;
  }
  return `community/${communityId}/dm-member-count?provider=${provider}`;
};

export const getThreadsDataApiUrl = (communityId, filters) =>
  `community/${communityId}/threads?${filters}`;

export const getSupportThreadsDataApiUrl = (
  communityId,
  filters,
  size,
  page,
  isChatBotMessage = false,
) =>
  `${
    isChatBotMessage
      ? `support/filter/${communityId}/v1?&page=${page}&size=${size}&filter=${filters}`
      : `support/filter/${communityId}?isChatBotConversation=${isChatBotMessage}&page=${page}&size=${size}&filter=${filters}`
  }`;

export const getThreadDataApiUrl = (communityId, threadId) =>
  `community/${communityId}/thread/${threadId}`;

export const getDeleteMemberApiUrl = (memberId, communityId) =>
  `member?memberId=${memberId}&communityId=${communityId}`;

// API name changed from member/roles to
export const getMembersRolesApiUrl = (communityId) => `communityTags/?communityId=${communityId}`;

export const getDiscordOnboardingFormURL = (communityId) =>
  `community/${communityId}/onboarding-form`;

export const getMemberSendMessageApiUrl = () => `message/sendDM`;
export const getMemberBulkMessageApiUrl = () => `message/sendBulkDM`;

export const getSendMemberTokensApiUrl = () => `reward/send`;

export const getListRewardConfigApiUrl = (communityId) =>
  `reward-config/fetch?communityId=${communityId}`;
export const getModifyRewardConfigApiUrl = () => `reward-config/modify`;
export const getListRewardInsightApiUrl = (communityId) =>
  `reward/leader-board?communityId=${communityId}`;

export const getMessageReplyApiUrl = () => `message/replyToMessage`;

export const getMemberCsvDownloadApiUrl = (communityId, filters) =>
  `member/csv?communityId=${communityId}&${filters}`;

export const getMessageTopicsListApiUrl = (communityId) =>
  `topics/community/${communityId}/unigram/topics?size=20`;

export const getSocketApiUrl = `${process.env.REACT_APP_API_BASE_URL}/ws`;

export const getLogoutApirUrl = () => `auth/logout`;
export const getLoginApirUrl = () => `auth/signin`;
export const getSignUpApiUrl = () => `auth/signup`;
export const getCompleteSignupApiUrl = () => `auth/complete-signup`;
export const getUserInfoApirUrl = () => `user/me`;
export const getResetPasswordApiUrl = () => `auth/resetPassword`;
export const getChangePasswordApiUrl = () => `auth/changePassword`;
export const getMagicLinkURL = () => `auth/magic-link`;
export const getValidEmailApiUrl = (email) => `user/checkEmailAvailability?email=${email}`;
export const sendEmailVerificationApiUrl = () => `auth/user-verify`;
export const getSignupUserInfoFromParamTokenApiUrl = (token) => `user/exchange-info?token=${token}`;

export const getEvent = () => `auth/changePassword`;
export const getActivitySummaryApiUrl = (communityId, fromDate, toDate) =>
  `activity/summary?communityId=${communityId}&fromDate=${fromDate}&toDate=${toDate}`;
export const getActivitySummaryInsightsApiUrl = (communityId, provider, fromDate, toDate) =>
  `activity/v1/summary?communityId=${communityId}&provider=${provider}&fromDate=${fromDate}&toDate=${toDate}`;
export const getActivityMembersHelpfulApiUrl = (communityId, provider, fromDate, toDate) =>
  `activity/v1/helpful-contributors?communityId=${communityId}&provider=${provider}&fromDate=${fromDate}&toDate=${toDate}&size=${activityPageMembersLimit}`;
export const getActivityMembersDiscussionApiUrl = (communityId, provider, fromDate, toDate) =>
  `activity/v1/discussion-contributors?communityId=${communityId}&provider=${provider}&fromDate=${fromDate}&toDate=${toDate}&size=${activityPageMembersLimit}`;
export const getActivityMembersNewApiUrl = (communityId, provider, fromDate, toDate) =>
  `activity/v1/new-members/inactive?communityId=${communityId}&provider=${provider}&fromDate=${fromDate}&toDate=${toDate}&size=${activityPageMembersLimit}`;
export const getStaffApiUrl = (communityId) => `community/staff?communityId=${communityId}`;
export const getStaffDeleteIntegrationApiUrl = (id) => `community/staff/integration/${id}`;
export const getStaffDeleteApiUrl = (communityId, staffId) =>
  `user?communityId=${communityId}&staffId=${staffId}`;
export const getInvitedApiUrl = (communityId) => `community/invitedUser?communityId=${communityId}`;
export const getActivityProviderSummaryApiUrl = (communityId, filters) =>
  `activity/provider-summary?communityId=${communityId}&${filters}`;
export const getActivityAllChannelsApiUrl = (communityId) =>
  `channel/allTextChannels?communityId=${communityId}`;
export const getActivityMessagesPostedApiUrl = (communityId, filters) =>
  `activity/messages-summary?communityId=${communityId}&${filters}`;
export const getActivityTopChannelsApiUrl = (communityId, fromDate, toDate) =>
  `activity/top-channels?communityId=${communityId}&fromDate=${fromDate}&toDate=${toDate}`;
export const getActivityTopMessagesApiUrl = (communityId, provider, fromDate, toDate) =>
  `activity/top-messages?communityId=${communityId}&fromDate=${fromDate}&toDate=${toDate}&size=5&page=0&provider=${provider}`;
export const getCommunityNotificationCountApiUrl = (communityId) =>
  `notification/count/${communityId}`;
export const getMemberCountByIntegrationId = (communityId, integrationId) =>
  `community/${communityId}/member-count-summary/${integrationId}`;
export const getMemberCountByCommunityId = (communityId) =>
  `community/${communityId}/member-count-summary`;
export const getCommunityOneNotificationReadApiUrl = (notificationId) =>
  `notification/readOne/${notificationId}`;
export const getCommunityAllNotificationReadApiUrl = (communityId) =>
  `notification/readAll/${communityId}`;
export const getCommunityNotificationListApiUrl = (communityId, page, size) =>
  `notification/list?communityId=${communityId}&page=${page}&size=${size}`;

export const getIntegrationChannelConfigsUrl = (integrationId) =>
  `integration/${integrationId}/channels`;

export const getChannelDetailsUrl = (communityId) =>
  `community/${communityId}/channels?platform=slack,discord,github`;

export const getChannelDetailsTrainingUrl = (communityId) =>
  `community/${communityId}/channels?platform=slack,discord,github,ms_teams`;

export const getSlackChannelDetailsUrl = (communityId) =>
  `community/${communityId}/channels?platform=slack`;

export const getDiscourseChannelDetailsUrl = (communityId) =>
  `community/${communityId}/channels?platform=discourse`;

export const getDiscordChannelDetailsUrl = (communityId) =>
  `community/${communityId}/channels?platform=discord`;

export const getInternalSlackChannelDetailsUrl = (communityId) =>
  `community/${communityId}/channels?platform=slackInternal`;

export const getInternalSlackConnectApiUrl = (orgId) => `organization/${orgId}/internalIntegration`;

export const getChannelDetailsUrlForInstall = (communityId) =>
  `chat-bot/${communityId}/qna-channels?platform=slack,discord,github,ms_teams`;

export const getDeleteIntegrationApiUrl = (integrationId) =>
  `integration?integrationId=${integrationId}`;

export const getDeleteIntegrationInternalApiUrl = (integrationId) =>
  `integration/internal?integrationId=${integrationId}`;

export const getDeleteCommunityApiUrl = (communityId) => `community?communityId=${communityId}`;
export const getSaveCommunityApiUrl = () => `community`;

export const updateSaveCommunityApiUrl = () => `community/update`;

export const updateCommunityPreferenceApiUrl = (orgId) => `organization/${orgId}/preferences`;

export const getCommunityPreferenceApiUrl = (orgId) => `organization/${orgId}`;
// API endpoint to create an organisation
export const getSaveOrganisationApiUrl = (userId) => `organization/${userId}`;

export const getIntegrationMembersAndChannelsApiUrl = (integrationId) =>
  `integration/loadMembersAndChannels?integrationId=${integrationId}`;
export const getIntegrationLoadMessageApiUrl = (integrationId) =>
  `integration/loadMessages?integrationId=${integrationId}`;
export const getIntegrationLoadReactionsApiUrl = (integrationId) =>
  `integration/loadReactions?integrationId=${integrationId}`;
export const getCommunityLoadSegmentsApiUrl = (communityId) =>
  `member/segments?communityId=${communityId}`;

export const getAddNewTeamMateApiUrl = () => `community/invite`;

export const getLandingPageApiUrl = (communityId) =>
  `community/landing-page/config?communityId=${communityId}`;
export const saveLandingPageApiUrl = () => `community/landing-page/config`;

// region content apis
export const getListDraftedContentApiUrl = (communityId, page, size, query) =>
  `content/scheduled-content/search?communityId=${communityId}&page=${page}&size=${size}&status=DRAFT${getQueryString(
    query,
  )}`;

export const getListScheduledContentApiUrl = (communityId, page, size, status, query) =>
  `community/${communityId}/content?&page=${page}&size=${size}&status=${status}${getQueryString(
    query,
  )}`;

export const getListPostedContentApiUrl = (communityId, page, size, status, query) =>
  `community/${communityId}/content?&page=${page}&size=${size}&status=${status}${getQueryString(
    query,
  )}`;

export const getListContentTemplatesApiUrl = (communityId, page, size, status, query) =>
  `community/${communityId}/content?page=${page}&size=${size}&status=${status}${getQueryString(
    query,
  )}`;

export const getListAllContentApiUrl = (communityId, page, size) =>
  `content/scheduled-content/community/${communityId}/search?page=${page}&size=${size}`;
export const getContentApiUrl = (contentId) => `content/${contentId}`;
export const getCreateContentApiUrl = () => `content/scheduled-content`;
export const getContentScheduleApiUrl = (contentId) =>
  `content/scheduled-content/${contentId}/schedule`;
export const getContentImageUploadApiUrl = (contentId) =>
  `content/scheduled-content/${contentId}/image`;
export const getPublishContentApiUrl = (contentId) =>
  `content/scheduled-content/${contentId}/publish`;
export const getNewContentFromTemplateApiUrl = (templateId) =>
  `content/scheduled-content/${templateId}/create-from-template`;
export const createNewTemplate = () => `message/template`;
export const createNewTemplateCommunitySpecific = (communityId) =>
  `message/${communityId}/template`;
export const getContentFromId = (contentId) => `content/${contentId}`;
// endregion

// region weekly summary apis
export const getWeeklySummaryApiUrl = (summaryId) => `content/weekly-summary/${summaryId}`;
export const getWeeklySummaryScheduleApiUrl = (summaryId) =>
  `content/weekly-summary/${summaryId}/schedule`;
export const getPublishWeeklySummaryApiUrl = (summaryId) =>
  `content/weekly-summary/${summaryId}/publish`;

// SelectSummary component API

// export const getCommunityTopMessagesForWeeklySummaryApiUrl = (communityId) =>
//   `content/weekly-summary/community/${communityId}/top-messages`;

// export const getCommunityUpcomingEventsForWeeklySummaryApiUrl = (communityId) =>
//   `content/weekly-summary/community/${communityId}/upcoming-events`;

// export const getCommunityTopContributorsForWeeklySummaryApiUrl = (communityId) =>
//   `content/weekly-summary/community/${communityId}/top-contributors`;

/**
 * Start SelectSummary component API
 */
export const getCommunityTopMessagesForWeeklySummaryApiUrl = (
  communityId,
  page,
  size,
  replyWeight = 1,
  reactionWeight = 1,
) => {
  const currentDate = new Date().toISOString();
  const weekBeforeDate = new Date(new Date().getTime() - 7 * 24 * 3600 * 1000).toISOString();
  return `content/weekly-summary/community/${communityId}/top-messages?from=${weekBeforeDate.toString()}&to=${currentDate.toString()}&page=${page}&size=${size}&reply_weight=${replyWeight}&reaction_weight=${reactionWeight}`;
};
export const getCommunityUpcomingEventsForWeeklySummaryApiUrl = (
  communityId,
  page,
  size,
  replyWeight = 1,
  reactionWeight = 1,
) => {
  const currentDate = new Date().toISOString();
  const weekBeforeDate = new Date(new Date().getTime() - 30 * 24 * 3600 * 1000).toISOString();
  return `content/weekly-summary/community/${communityId}/upcoming-events?from=${weekBeforeDate.toString()}&to=${currentDate.toString()}&page=${page}&size=${size}&reply_weight=${replyWeight}&reaction_weight=${reactionWeight}`;
};
export const getCommunityTopContributorsForWeeklySummaryApiUrl = (
  communityId,
  page,
  size,
  replyWeight = 1,
  reactionWeight = 1,
) => {
  const currentDate = new Date().toISOString();
  const weekBeforeDate = new Date(new Date().getTime() - 30 * 24 * 3600 * 1000).toISOString();
  return `content/weekly-summary/community/${communityId}/top-contributors?from=${weekBeforeDate.toString()}&to=${currentDate.toString()}&page=${page}&size=${size}&reply_weight=${replyWeight}&reaction_weight=${reactionWeight}`;
};
/**
 * Creates a new weekly-summary entity
 */
export const postSetDateAudience = () => `content/weekly-summary`;

/**
 * Weekly Summary List
 */
export const getCommunityWeeklySummaryListApiUrl = (
  communityId,
  page,
  size,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  status,
) => {
  return `content/weekly-summary/community/${communityId}/list?page=${page}&size=${size}`;
};

export const getListCommunityWeeklySummaryApiUrl = (communityId, page, size) =>
  `content/weekly-summary/community/${communityId}/list?page=${page}&size=${size}`;
export const getListDraftedCommunityWeeklySummaryApiUrl = (communityId, page, size) =>
  `content/weekly-summary/community/${communityId}/list?page=${page}&size=${size}&status=DRAFT`;
export const getListScheduledCommunityWeeklySummaryApiUrl = (communityId, page, size) =>
  `content/weekly-summary/community/${communityId}/list?page=${page}&size=${size}&status=SCHEDULED`;
export const getListPostedCommunityWeeklySummaryApiUrl = (communityId, page, size) =>
  `content/weekly-summary/community/${communityId}/list?page=${page}&size=${size}&status=POSTED`;
// endregion

// AUTOMATION Workflow APIs
export const getCreateWorkflowApiUrl = () => `workflow/createWorkflow`;
export const getWorkflowListUrl = (communityId, pageNo, pageSize) =>
  `reports/workflow/list?communityId=${communityId}&page=${pageNo}&size=${pageSize}`;
export const getRecentWorkflowListUrl = (communityId, pageNo, pageSize) =>
  `reports/workflow/all?communityId=${communityId}&page=${pageNo}&size=${pageSize}`;
export const getFlowchartByIdUrl = (communityId, workflowId) =>
  `workflow/flow?communityId=${communityId}&workflowId=${workflowId}`;
export const getFlowchartByIdWithDateUrl = (
  communityId,
  workflowId,
  dateRange,
  fromDate,
  toDate,
) => {
  if (dateRange && dateRange !== 'CUSTOM') {
    return `workflow/flow?communityId=${communityId}&workflowId=${workflowId}&dateRange=${dateRange}`;
  } else {
    return `workflow/flow?communityId=${communityId}&workflowId=${workflowId}&from=${fromDate}&to=${toDate}`;
  }
};

export const postModifyFlowChartApiUrl = () => `workflow/steps/modify`;
export const saveFlowChartApiUrl = () => `workflow/steps/save`;
export const postModifyWorkflowStatus = () => `workflow/status/modify`;
export const publishTemplate = () => `workflow/template/publish`;
export const activateTemplate = () => `workflow/template/activate`;
export const getCreateAutomationCriteria = (communityId, workflowId) =>
  `workflow/${communityId}/get/${workflowId}/filterCriteria`;
export const getAutomationCriteriaForSubtypeTrigger = (triggerEvent, triggerSubtype) =>
  `workflow/getCriteria?triggerEvent=${triggerEvent}&triggerEventSubtype=${triggerSubtype}`;
export const setCreateAutomationCriteria = (communityId, workflowId) =>
  `workflow/${communityId}/save/${workflowId}/filterCriteria`;
export const editWorkflowTriggerType = () => `workflow/modify`;
export const editWorkflowName = () => `workflow/modifyName`;
export const cloneAutomationWorkflowUrl = () => `workflow/clone`;
export const createNewNodeFromApiUrl = () => `workflow/steps/add`;
export const editNewNodeFromApiUrl = () => `workflow/steps/edit`;
export const removeChangesFromApiUrl = () => `workflow/steps/removeChanges`;
export const deleteNodeFromApiUrl = (workflowId, stepId) =>
  `workflow/${workflowId}/steps/${stepId}`;

export const getWorkflowStatusDetailsApiUrl = (workflowId) =>
  `workflow/${workflowId}/workflow-status-details`;

// Automation Templates APIs
export const getRecentAutomationsApiUrl = (communityId) =>
  `reports/workflow/list?communityId=${communityId}&page=0&size=3`;
export const getAutomationTemplatesCategoriesListApiUrl = (communityId) =>
  `workflow/template/template-category`;
export const getAutomationTempatesByCategoryApiUrl = (category, page, size, platforms) =>
  `workflow/template/template-by-categories?categories=${category}&platforms=${platforms}&page=${page}&size=${size}`;
export const getAutomationTemplateByIdApiurl = (templateId) =>
  `workflow/template/${templateId}/preview`;

// Automation Report/Analytics APIs
export const automationReportByBlockIdUrl = (
  workflowId,
  blockId,
  status,
  dateRange,
  fromDate,
  toDate,
  searchValue,
  pageNo,
) => {
  if (dateRange && dateRange !== 'CUSTOM') {
    return `reports/workflow/${workflowId}/block/${blockId}?status=${status}&dateRange=${dateRange}&search=${searchValue}&page=${pageNo}&size=${pageSize}`;
  } else {
    return `reports/workflow/${workflowId}/block/${blockId}?status=${status}&from=${fromDate}&to=${toDate}&search=${searchValue}&page=${pageNo}&size=${pageSize}`;
  }
};

export const getAutomationReportCsvDownloadApiUrl = (
  workflowId,
  blockId,
  status,
  dateRange,
  fromDate,
  toDate,
) => {
  if (dateRange && dateRange !== 'CUSTOM') {
    return `reports/workflow/${workflowId}/block/${blockId}/export?status=${status}&dateRange=${dateRange}`;
  } else {
    return `reports/workflow/${workflowId}/block/${blockId}/export?status=${status}&fromDate=${fromDate}&toDate=${toDate}`;
  }
};

export const getAutomationReportBlockStatusApiUrl = (
  workflowId,
  blockId,
  dateRange,
  fromDate,
  toDate,
) => {
  if (dateRange && dateRange !== 'CUSTOM') {
    return `reports/workflow/${workflowId}/block/${blockId}/status?dateRange=${dateRange}`;
  } else {
    return `reports/workflow/${workflowId}/block/${blockId}/status?from=${fromDate}&to=${toDate}`;
  }
};

export const postUseTemplateApiUrl = () => `workflow/template/useTemplate`;

// Retention Analytics APIs
export const getRententionAnalytics = (communityId, filters) =>
  `analytics/retention?communityId=${communityId}&${filters}`;

// Engagement Analytics APIs
export const getEngagementAnalytics = (communityId, filters) =>
  `analytics/engagement?communityId=${communityId}&${filters}`;

// Engagement Analytics APIs
export const getEngagementAnalyticsTrendApiUrl = (communityId, filters) =>
  `analytics/engagement/trend?communityId=${communityId}&${filters}`;

// Segment Settings
export const getSegmentSettingsScore = (communityId) => `community/${communityId}/member-levels`;

// Notifications APIs
export const getNotificationsApiUrl = (communityId, page, pageSize) =>
  `user/action/notification/list?communityId=${communityId}&size=${pageSize}&page=${page}`;
export const getNotificationDetailedApiUrl = (notificationId) =>
  `user/action/notification/detail?notificationId=${notificationId}`;

export const getNotificationSettingsURL = (communityId) => `notification/settings/${communityId}`;

// Endpoints for content-scheduler messages

// Get message content from communityID
export const getListScheduledTemplateApiUrl = () => `message/template/?getAll=true&scope=Local`;
export const getMessageContent = (communityId) => `community/${communityId}/content`;
export const postContentMessage = (communityId) => `community/${communityId}/content`;
export const editContentMessage = (contentId) => `content/${contentId}`;
export const getTemplateCategories = () => `message/template-category`;
export const getMessageTemplates = () => `message/template?getAll=true`;
export const getTemplateFromCategory = (category) =>
  `message/template-by-categories?categories=${category}`;

// MemberPage: getCriteria
export const getCriteria = (communityId) => `community/${communityId}/criteria`;

// Message/Support Page: getCriteria
export const getCriteriaForMessage = (communityId) =>
  `community/${communityId}/criteria?service=MESSAGE`;

export const getCriteriaForMessageDefault = (communityId) =>
  `community/${communityId}/criteria/default?service=MESSAGE`;

// MemberPage: criteria
export const spotCriteria = (communityId, criteriaId) =>
  `community/${communityId}/criteria/${criteriaId}`;

// Support page: criteria
export const spotSupportCriteria = (communityId, criteriaId) =>
  `community/${communityId}/criteria/${criteriaId}?service=MESSAGE`;
// MemberPage: Total Count
export const getTotalMemberCountURL = (communityId) => `member/count?communityId=${communityId}`;

//Discourse Integration
export const getDiscourseIntegrationSecret = (communityId) =>
  `discourse/${communityId}/new-integration-secret`;

export const getDiscourseIntegrationUrl = (communityId, domain) =>
  `discourse/${communityId}/integration-url?domain=${domain}`;

export const discourseIntegrationPostApiUrl = (communityId) =>
  `discourse/${communityId}/integration`;

export const freshdeskIntegrationPostApiUrl = (communityId) =>
  `freshdesk/${communityId}/integration`;

export const addTagtoMembersUrl = (communityId) => `community/${communityId}/member-tag`;

// Get all member, hashtag
export const getMembersForMentions = (communityId, searchQuery) =>
  `member/search?communityId=${communityId}&q=${searchQuery}`;
// Get all roles for discord
export const getMemberForRolesDiscord = (communityId, searchQuery) =>
  `communityTags/search?communityId=${communityId}&q=${searchQuery}`;

export const getChannelsForHashtag = (communityId) =>
  `channel/allTextChannels?communityId=${communityId}`;

export const trackUserAnalyticsApiUrl = () => `user/analytics/v2/track`;

export const uploadCSVURL = (communityId) => `community/${communityId}/member-tag/uploadFile?file`;

export const memberImportUpload = (communityId) => `community/${communityId}/member-import/upload`;

export const processMemberImport = (communityId) =>
  `community/${communityId}/member-import/process`;

export const processingMemberImportCount = (communityId) =>
  `community/${communityId}/member-import/processing-count`;

export const slackConnectUrl = (communityId) => `community/${communityId}/slack-connect`;

export const discordConnectUrl = (communityId) => `community/${communityId}/discord-connect`;

export const getConnectUsersByProvider = (communityId, selectedProvider) =>
  `community/${communityId}/${selectedProvider}/connected-users`;

export const getWorkflowSurvey = (communityId) => `workflow/${communityId}/survey`;

export const getLastSyncDetails = (communityId) => `community/${communityId}/last-sync`;

export const sendTestMessageButtonBlockUrl = (memberId) => `message/testButtonMessage/${memberId}`;

export const sendTestMessageUrl = () => `message/testMessage`;

export const sendAlertTestMessageUrl = () => `message/testAlert`;

export const sendBulkTestMessageUrl = () => `message/testBulkDM`;

export const discordCustomBotUrl = (integrationId) =>
  `integration/${integrationId}/discord-custom-bot`;

export const discordCustomBotStep1 = (integrationId) =>
  `integration/${integrationId}/discord-custom-bot/step1`;

export const discordCustomBotStep2 = (integrationId) =>
  `integration/${integrationId}/discord-custom-bot/step2`;

export const discordInteractionUrl = (integrationId) => `discord/${integrationId}/interaction-url`;

// Slack custom bot
export const slackCustomBotUrl = (integrationId) =>
  `chat-bot/custom-bot/slack/steps?integrationId=${integrationId}`;

export const postSlackCustomBotDataUrl = () => `chat-bot/custom-bot/slack/steps`;

export const refreshSlackCustomBotUrl = (integrationId) =>
  `chat-bot/custom-bot/slack/refresh-bot?integrationId=${integrationId}`;

export const deleteSlackCustomBotApiUrl = (integrationId) =>
  `chat-bot/custom-bot/slack/delete-bot?integrationId=${integrationId}`;

// Payments
export const getPaymentsStatusUrl = (orgId) => `subscription/${orgId}/status`;

export const paymentsListUrl = (orgId, frquency) =>
  `subscription/${orgId}/list?frequency=${frquency}`;

export const cancelSubscriptionUrl = (orgId) => `subscription/${orgId}/cancel`;

// Support Bot Payments
export const getSupportBotPaymentsStatusUrl = (orgId) =>
  `subscription/${orgId}/status?feature=CHATBOT`;

export const getSupportCurrentUsageStatusUrl = (orgId) =>
  `subscription/${orgId}/usage?feature=CHATBOT`;

export const supportBotpaymentsListUrl = (orgId, frquency) =>
  `subscription/${orgId}/list?frequency=${frquency}&feature=CHATBOT`;

export const supportBotcancelSubscriptionUrl = (orgId) =>
  `subscription/${orgId}/cancel?feature=CHATBOT`;

export const getUserIdentifyTrackUrl = () => `user/analytics/identify`;
export const saveWorkSpaceUrl = () => `user/last-accessed-workspace`;
export const getPlatformDetailUrl = () => `community/platform`;

export const getCommunityDetails = (communityId, organisationId) =>
  `community?organisationId=${organisationId}&communityId=${communityId}`;

export const getResendInviteUrl = () => `community/resend-invite`;

export const postStaffApiUrl = () => `community/staff`;

export const getInviteDeleteApiUrl = (communityId, staffId) =>
  `community/invitedUser?communityId=${communityId}&id=${staffId}`;

// Outreach Templates APIs
export const getRecentOutreachApiUrl = (communityId) =>
  `community/${communityId}/outreach/list?&page=0&size=3`;
export const getOutreachListApiUrl = (
  communityId,
  messageStatus,
  providerType,
  searchValue,
  page,
  size = 20,
) =>
  `community/${communityId}/outreach/list?messageStatus=${messageStatus}&providerType=${providerType}&searchValue=${searchValue}&page=${page}&size=${size}`;
export const getOutreachTemplatesCategoriesListApiUrl = (communityId) =>
  `outreach-template-category`;
export const getOutreachTempatesByCategoryPlatformApiUrl = (
  communityId,
  category,
  platforms,
  page,
  size,
  isGlobal,
) => {
  let urlTemp;

  if (category || !isGlobal) {
    urlTemp = `isGlobal=${isGlobal}&templateCategory=${category}&platforms=${platforms}&page=${page}&size=${size}`;
  } else if (isGlobal && !category) {
    urlTemp = `platforms=${platforms}&page=${page}&size=${size}`;
  }

  return `community/${communityId}/outreach-template/?${urlTemp}`;
};
export const getOutreachTemplateByIdApiUrl = (outreachTemplateId) =>
  `outreach-template/${outreachTemplateId}/preview`;

// DELETE
export const deleteOutreachApiUrl = (outreachId) => `outreach/${outreachId}`;

// DELETE
export const deleteCustomOutreachTemplateUrl = (communityId, templateId) =>
  `community/${communityId}/outreach-template/${templateId}`;

// POST
export const cloneOutreachApiUrl = () => `outreach/clone`;

// PUT
export const archiveOutreachApiUrl = (communityId, outreachId) =>
  `community/${communityId}/outreach/${outreachId}/archive`;

// PUT
export const rescheduleOutreachApiUrl = (outreachId, timestamp) =>
  `outreach/${outreachId}/reschedule?scheduleAt=${timestamp}`;

export const postUseOutreachTemplateApiUrl = () => `outreach-template/useTemplate`;

export const createOutreachApiUrl = (communityId) => `community/${communityId}/outreach`;

export const updateOutreachApiUrl = (communityId, outReachId) =>
  `community/${communityId}/outreach/${outReachId}`;

export const sendNowOutreach = () => `outreach/send`;

export const sendTestMessage = () => `outreach/testOutreach`;

export const sendTestMessageByEmail = () => `outreach/testOutreachEmail`;

export const cancelScheduledOutreachURL = (outreachId) => `outreach/${outreachId}/cancelSchedule`;

export const rateLimitURL = (communityId, outreachType, domainEmail, sentTo) =>
  `ratelimit/?communityId=${communityId}&domainEmail=${domainEmail}&communicationType=${outreachType}&${sentTo}`;

export const rateLimitPostURL = () => `ratelimit/`;

export const saveAsTemplateUrl = (communityId) => `community/${communityId}/outreach-template`;

export const getOutreachReportByIdApiUrl = (outreachId) => `outreach/${outreachId}/details`;

export const setOureachNameByIdApiUrl = (outreachId) => `outreach/${outreachId}/editName`;

export const getOutreachReportMemberDataApiUrl = (
  outreachId,
  metric,
  searchValue,
  page,
  pageSize,
  filteredLink,
) =>
  `outreach/${outreachId}/analytics?metric=${metric}&searchValue=${searchValue}&page=${page}&size=${pageSize}&url=${filteredLink}`;

export const getOutreachReportMemberDataApiUrlWithoutMetric = (
  outreachId,
  searchValue,
  page,
  pageSize,
) => `outreach/${outreachId}/analytics?searchValue=${searchValue}&page=${page}&size=${pageSize}`;

export const getOutreachReportCsvDownloadApiUrl = (outreachId, metric, searchValue, url) =>
  `outreach/${outreachId}/export?metric=${metric}&searchValue=${searchValue}&url=${url}`;

// Year end review
export const getYearEndReviewDataApiUrl = (reviewId) => `year-review/community/${reviewId}`;
export const getActivityYearEndReviewBannerApiUrl = (communityId) =>
  `year-review/community/${communityId}/details`;
export const postYearEndOgImageUrl = (communityId) =>
  `year-review/add/image/community/${communityId}`;
// Topic
export const getTopicModelApiUrl = (communityId, page) =>
  `topics/community/${communityId}/topics?page=${page || 0}&size=${20}`;
export const getTopicModelUnigramApiUrl = (communityId, page) =>
  `topics/community/${communityId}/unigram/topics?page=${page || 0}&size=${20}`;
export const getTopicModelBigramApiUrl = (communityId, page) =>
  `topics/community/${communityId}/bigram/topics?page=${page || 0}&size=${20}`;

export const getLinkInOutreachReports = (outreachId) => `link/OUTREACH/${outreachId}`;

export const getMemberFromAction = (communityId, memberId) =>
  `member/v4?communityId=${communityId}&memberId=${memberId}`;

export const getCustomFieldsForMemberFilter = (communityId) =>
  `community/${communityId}/custom-fields`;
// HUBSPOT INTEGRATION

export const getHubspotConfigApiUrl = (hubspotIntegration) =>
  `hubspot/${hubspotIntegration}/receive-config`;
export const getHubspotSendConfigApiUrl = (hubspotIntegration) =>
  `hubspot/${hubspotIntegration}/send-config`;
export const getHubspotDropdownDataApiUrl = (hubspotIntegration, isFromReceive) =>
  `hubspot/${hubspotIntegration}/contact-properties?isFromReceive=${isFromReceive}`;
export const getThreadoDropdownDataApiUrl = (hubspotIntegration) => `hubspot/threado-properties`;
export const getThreadoCustomPropertyApiUrl = (hubspotIntegration) =>
  `hubspot/${hubspotIntegration}/threado-custom-properties`;

// Timezone list api
export const getTimezoneListApiUrl = () => `organization/timezone`;

// Create new tag
export const createNewTagApiUrl = (communityId) => `community/${communityId}/tags`;

export const getMemberWidgetDataUrl = (communityId, provider) =>
  `member/reports/summary/community/${communityId}?provider=${provider}`;

export const getActivitiesWidgetDataUrl = (communityId, provider) =>
  `message/reports/summary/community/${communityId}?provider=${provider}`;

export const getTopMemberWidgetDataUrl = (communityId, provider) =>
  `member/reports/top-member/community/${communityId}?provider=${provider}`;

export const getTopMessageWidgetDataUrl = (communityId, provider) =>
  `message/reports/top-messages/community/${communityId}?provider=${provider}`;

export const getChannelInsightStaticDataUrl = (communityId, provider) =>
  `message/reports/channel-activity/summary/community/${communityId}?provider=${provider}`;
export const getChannelInsightListUrl = (communityId, provider) =>
  `message/reports/channel-activities/community/${communityId}?provider=${provider}`;

export const getTopicInsightStaticDataUrl = (communityId, provider) =>
  `message/reports/topic-activity/summary/community/${communityId}?provider=${provider}`;
export const getTopicInsightListUrl = (communityId, provider) =>
  `message/reports/topic-activities/community/${communityId}?provider=${provider}`;
// CHART
export const getChartForActivity = (communityId, provider, type, frequency) =>
  `message/reports/activity/trend/community/${communityId}?provider=${provider}&type=${type}&frequency=${frequency}`;
export const getActivityTopCard = (communityId, provider) =>
  `message/reports/activity/summary/community/${communityId}?provider=${provider}`;
export const getChartForSentiment = (communityId, provider, frequency) =>
  `message/reports/sentiments/trend/community/${communityId}?provider=${provider}&frequency=${frequency}`;
export const getSentimentTopCard = (communityId, provider) =>
  `message/reports/sentiments/summary/community/${communityId}?provider=${provider}`;

// Member Analytics API Urls
export const getNewMembersInsightStaticDataUrl = (communityId, provider) =>
  `member/reports/new-member/summary/community/${communityId}?provider=${provider}`;
export const getTotalMembersInsightStaticDataUrl = (communityId, provider) =>
  `member/reports/total-member/summary/community/${communityId}?provider=${provider}`;
export const getChartForNewMembers = (communityId, provider, frequency) =>
  `member/reports/new-member/trend/community/${communityId}?provider=${provider}&frequency=${frequency}`;
export const getChartForTotalMembers = (communityId, provider, frequency) =>
  `member/reports/total-member/trend/community/${communityId}?provider=${provider}&frequency=${frequency}`;
export const getTopMembersListDataUrl = (communityId, provider, page, size) =>
  `member/reports/top-member/trend/community/${communityId}?provider=${provider}&page=${page}&size=${size}`;
export const getTopMembersInsightStaticDataUrl = (communityId, provider) =>
  `member/reports/top-member/summary/community/${communityId}?provider=${provider}`;
export const getPotentialChampionsListDataUrl = (communityId, provider, page, size) =>
  `member/reports/potential-new-champion/trend/community/${communityId}?provider=${provider}&page=${page}&size=${size}`;
export const getAtRiskMembersDataUrl = (communityId, provider, page, size) =>
  `member/reports/at-risk-member/community/${communityId}?provider=${provider}&page=${page}&size=${size}`;

export const getTeamPerformanceInsightListUrl = (communityId, provider) =>
  `member/reports/team-performance/community/${communityId}?provider=${provider}`;

export const getResponsivenessTrendChartUrl = (communityId, provider, frequency, type) =>
  `message/reports/responsiveness/trend/community/${communityId}?provider=${provider}&frequency=${frequency}&type=${type}`;
export const getResponsivenessInsightStaticDataUrl = (communityId, provider) =>
  `message/reports/responsiveness/summary/community/${communityId}?provider=${provider}`;

export const getRetentionInsightStaticDataUrl = (communityId, provider) =>
  `member/reports/retention/summary/community/${communityId}?provider=${provider}`;
export const getRetentionTrendChartUrl = (communityId, provider, frequency) =>
  `member/reports/retention/community/${communityId}?provider=${provider}&frequency=${frequency}`;

// Chatbot APIs
export const getDataSourceByIntegrationApiUrl = (communityId, integrationName) =>
  `chat-bot/community/${communityId}/integration/${integrationName}/data-sources`;

export const getDataSourceDetailsApiUrl = (communityId) =>
  `chat-bot/data-sources?communityId=${communityId}`;
export const getDataSourceStatusApiUrl = (communityId) =>
  `chat-bot/data-sources/status?communityId=${communityId}`;

export const postDataSourceDetailsApiUrl = () => `chat-bot/data-sources`;

export const sendMessageTestBotApiUrl = (communityId) =>
  `rpc/chat-bot/conversation?communityId=${communityId}`;

export const deleteDataSourceApiUrl = (communityId, dataSourceId) =>
  `chat-bot/data-source?communityId=${communityId}&dataSourceId=${dataSourceId}`;

export const syncDataSourceApiUrl = (communityId, dataSourceType) =>
  `rpc/chat-bot/data-source/trigger?communityId=${communityId}&dataSourceType=${dataSourceType}`;

export const retrainUrlApiUrl = (communityId, dataSourceId, selectedOption) =>
  `chat-bot/data-source/retrain?dataSourceId=${dataSourceId}&communityId=${communityId}&RE_TRAIN=${selectedOption}`;

export const getChildUrlsApiUrl = (communityId, urlId) =>
  `chat-bot/data-source/outbound?dataSourceId=${urlId}&communityId=${communityId}`;

export const getChildUrlsListApiUrl = (communityId, urlId, trainingStatus, page, searchText) =>
  `chat-bot/data-source/url/search?dataSourceId=${urlId}&communityId=${communityId}&trainingStatus=${trainingStatus}&search=${searchText}&page=${page}&size=50`;

export const deleteChildUrlUsingIdApiUrl = (communityId, childUrlId) =>
  `chat-bot/data-source/outbound?outboundId=${childUrlId}&communityId=${communityId}`;

export const trainUrlSourceApiUrl = () => `rpc/chat-bot/data-source/train/url`;

export const trainGoogleDriveSourcePostUrl = (integrationId) =>
  `support/integration/${integrationId}/google-drive/train`;

export const supportTrainHelpAndCannedDataApiUrl = () => `support/train`;

// Bot Analytics
export const getBotReportsStaticDataUrl = (communityId, provider) =>
  `chatbot/reports/summary/community/${communityId}?provider=${provider}`;

export const getBotChartForActivity = (communityId, provider, frequency) =>
  `chatbot/reports/trend/community/${communityId}?provider=${provider}&frequency=${frequency}`;

export const retryChildUrlTrainingByIdApiUrl = (communityId, childUrlId, dataSourceId) =>
  `chat-bot/data-source/outbound/retrain?outboundId=${childUrlId}&dataSourceId=${dataSourceId}&communityId=${communityId}`;

export const retryPdfTrainingByIdApiUrl = (communityId, pdfId) =>
  `chat-bot/data-source/retrain?dataSourceId=${pdfId}&communityId=${communityId}`;

export const downloadFileByLinkApiUrl = (communityId, filePath) =>
  `api/file/${communityId}/get?path=${filePath}`;

export const downloadFileByPathLinkApiUrl = (communityId, filePath, date) =>
  `file/${communityId}/send-email?date=${date}&path=${filePath}`;

// Bot-customization
export const getBotCustomizationSettingUrl = (communityId, providerType) =>
  `chat-bot/bot-settings?communityId=${communityId}&platform=${providerType}`;
export const setBotCustomizationSettingUrl = () => `chat-bot/bot-settings`;
export const chatBotListenApiUrl = (communityId, platform) =>
  `chat-bot/qna-channel?communityId=${communityId}&platform=${platform}`;
export const postChatBotListenApiUrl = () => `chat-bot/qna-channel?communityId`;
export const onDeleteChatBotListenApiUrl = (workspaceId, channelId) =>
  `chat-bot/workspace/${workspaceId}/qna-channel/${channelId}`;

export const setChatBotStatusApiUrl = () => `chat-bot/status`;

export const setQuestionPrivateApiUrl = (communityId) => `chat-bot/dm?communityId=${communityId}`;

export const getChatbotSettingApiUrl = (communityId, platform) =>
  `chat-bot/bot-settings?communityId=${communityId}&platform=${platform}`;

export const sendInstallEmailApiUrl = (communityId, emails, type) =>
  `chat-bot/web/send-instruction?communityId=${communityId}&emails=${emails}&type=${type}`;

export const getChatbotWebInstallApiUrl = (communityId, platform) =>
  `chat-bot/install?communityId=${communityId}&platform=${platform}`;

export const getZendeskTicketsAssigneeList = (integrationId) =>
  `support/integration/${integrationId}/users`;
export const getZendeskTicketsTagsList = (integrationId) =>
  `support/integration/${integrationId}/tags`;

export const getZendeskTicketsFilterCriteriaApiUrl = (integrationId) =>
  `support/integration/${integrationId}/filter`;

export const getIntegrationCRMFilterCriteriaApiUrl = (integrationId) =>
  `crm/integration/${integrationId}/filter`;
export const saveZendeskTicketsFilterCriteriaApiUrl = (integrationId) =>
  `support/integration/${integrationId}/filter/save`;

export const trainingGocobaltApiUrl = (communityId, provider) =>
  `cobalt/sync-data?communityId=${communityId}&provider=${provider}`;

export const trainCannedResponseApiUrl = () => `rpc/chat-bot/data-source/train/canned-responses`;

export const getSupportTicketGroupsListApiUrl = (integrationId) =>
  `support/integration/${integrationId}/groups`;

export const setAgentHandOffSettingsApiUrl = () => `chat-bot/bot-handoff-settings`;
export const agentHandOffEnableDisable = () => `chat-bot/bot-handoff`;
// Slack alert
export const setSlackAlertApiUrl = (communityId) => `analytics/community/${communityId}/alerts`;
export const getSlackAlertApiUrl = (communityId, providerType, reportType) =>
  `analytics/community/${communityId}/alerts?provider=${providerType}&reportType=${reportType}`;
export const deleteSlackAlertApiUrl = (communityId, alertId, providerType) =>
  `analytics/community/${communityId}/alerts/${alertId}?provider=${providerType}`;
export const donwloadChartApiUrl = (communityId) =>
  `analytics/community/${communityId}/alerts/download`;
export const getSlackAlertByIdApiUrl = (communityId, alertId, providerType) =>
  `analytics/community/${communityId}/alerts/${alertId}?provider=${providerType}`;
export const updateSlackAlertByIdApiUrl = (communityId, alertId) =>
  `analytics/community/${communityId}/alerts/${alertId}`;

export const updateConversationStatus = () => `agent-assist/conversation-status`;

// Agent assist setup
export const getToneDefaultListApiUrl = () => `agent-assist/agent-tones`;

export const getAgentResponseChecksApiUrl = () => `agent-assist/agent-response-checks`;

export const getAgentAssistCustomizeResponseApiUrl = (communityId, platform) =>
  `agent-assist/settings?communityId=${communityId}&platform=${platform}`;

export const postAgentAssistSettingsApiUrl = () => `agent-assist/settings`;

export const getAgentsListApiUrl = (communityId) => `agent-assist/community/${communityId}/agents`;

export const postBulkInviteApiUrl = () => `community/bulk-invite`;

export const getAgentInstallListApiUrl = (communityId, platform) =>
  `agent-assist/agent-install-list?communityId=${communityId}&platform=${platform}`;

export const getInstallChromeAppNudgeStatusApiUrl = (communityId) =>
  `agent-assist/show-agent-assist-prompt?communityId=${communityId}`;

export const currentPageValidationNoAuthApiUrl = () => `agent-assist/validate-platform`;

// Getting ticket Id and verification if the platform is valid
export const postProcessUrlApiConstantApi = (communityId) =>
  `agent-assist/workspace/${communityId}/validate-url`;
// Summary for ticket
export const postSummaryTicketStreamApiUrl = (communityId, ticketId) =>
  `agent-assist/workspace/${communityId}/ticket/${ticketId}/summary`;
// Check for new questions in the ticket from the user, and fetch new question
export const getNewQuestionAvailableApiUrl = (communityId, ticketId) =>
  `agent-assist/workspace/${communityId}/ticket/${ticketId}/question`;
// Ask for new questions posted by the user
export const postNewQuestionStreamApiUrl = (communityId, ticketId) =>
  `chat-bot/agent-assist/community/${communityId}/ticket/${ticketId}/questions`;
// Ask for response - current stream call
export const postStreamApiUrl = () => `stream/v4`;
// Ask for refinement of text
export const postRefineTextApiUrl = (communityId) =>
  `chat-bot/agent-assist/community/${communityId}/refine`;
// Api to fetch options for power-up the main page
// GENERAL/TICKET
export const getOptionApiUrl = (pluginContext) =>
  `agent-assist/preferences/options?plugin-context=${pluginContext}`;
export const setActionForRefinePostApiUrl = (communityId) =>
  `agent-assist/workspace/${communityId}/action?botType=CHAT_TEST`;

export const setGetUserTonePreference = (communityId) => `agent-assist/preferences`;

export const setSelectedCommunityDataApiUrl = () => `user/switch-workspace`;

export const setLanguageSelectApiUrl = () => `agent-assist/language/settings`;
export const getListOfLanguagesApiUrl = () => `agent-assist/language/list`;
export const getLanguageSettingsApiUrl = (communityId) =>
  `agent-assist/community/${communityId}/language-selection`;
export const getBotResponseLengthApiUrl = (communityId) =>
  `agent-assist/settings/bot-response-length?communityId=${communityId}`;
export const setBotResponseLengthApiUrl = () => `agent-assist/settings/bot-response-length`;

/**
 * Constructs the API URL to fetch previous messages for a specific ticket in a workspace.
 * @param {string} workspaceId - The ID of the workspace.
 * @param {string} userId - The ID of the user.
 * @returns {string} The constructed URL for fetching previous messages.
 */
export const getPreviousChatHistoryApiUrl = (workspaceId, userId) =>
  `agent-assist/chat/pre-fill?communityId=${workspaceId}&agentId=${userId}&page=0&size=30&botType=CHAT_TEST`;

export const postFeedbackOnAskPageApiUrl = (workspaceId) =>
  `agent-assist/workspace/${workspaceId}/conversation/feedback`;

/**
 * Agnet assist analytics urls
 */

export const getAgentAssistAnalyticsOverviewApiUrl = (communityId, provider) =>
  `agent-assist/analytics/workspace/${communityId}/overview?provider=${provider}`;

export const getAgentAssistAnalyticsEngagementApiUrl = (communityId, provider, frequency) =>
  `agent-assist/analytics/workspace/${communityId}/trend/engagement?provider=${provider}&frequency=${frequency}`;

export const getAgentAssistBotResponseRateApiUrl = (communityId, provider) =>
  `agent-assist/analytics/workspace/${communityId}/bot-responses?provider=${provider}`;

export const getAgentAssistQueriesTrendApiUrl = (communityId, provider, frequency) =>
  `agent-assist/analytics/workspace/${communityId}/trend/queries?provider=${provider}&frequency=${frequency}`;

export const getAgentAssistAnalyticsAgentsUsageListApiUrl = (
  communityId,
  provider,
  currentPage,
  searchText,
) =>
  `agent-assist/analytics/workspace/${communityId}/agents-usage?provider=${provider}&page=${currentPage}&size=5&q=${searchText}`;

/**
 * Ai model selection
 */
export const getAiModelSettingsApiUrl = (communityId, aiModelType) => `support/model/find/${communityId}/${aiModelType}`;
export const getAiModelsListApiUrl = (aiModelType) => `support/model/list/${aiModelType}`;
export const saveAiModelApiUrl = (communityId) => `support/model/save/${communityId}`;
export const saveEditModelApiUrl = (communityId, aiModelId) =>
  `support/model/edit/${communityId}/modelId/${aiModelId}`;
export const deleteAiModelAccountApiUrl = (communityId, aiModelId) =>
  `support/model/delete/${communityId}/modelId/${aiModelId}`;
export const selectAiModelChangeApiUrl = (communityId) => `support/model/select/${communityId}`;

/**
 * Embedding model selection
 */
export const getEmbeddingAiModelSettingsApiUrl = (communityId) =>
  `embedding/model/find/${communityId}`;
export const getEmbeddingAiModelsListApiUrl = () => `embedding/model/list`;
export const saveEmbeddingAiModelApiUrl = (communityId) => `embedding/model/save/${communityId}`;
export const saveEditEmbeddingAiModelApiUrl = (communityId, aiModelId) =>
  `embedding/model/edit/${communityId}/modelId/${aiModelId}`;
export const deleteEmbeddingAiModelAccountApiUrl = (communityId, aiModelId) =>
  `embedding/model/delete/${communityId}/modelId/${aiModelId}`;
export const selectEmbeddingAiModelChangeApiUrl = (communityId) =>
  `embedding/model/select/${communityId}`;
