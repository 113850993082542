/* eslint-disable @typescript-eslint/no-explicit-any */

import { getActionIdCheckMapping, transformActionCheck } from '../redux/actionProvider/util';

export const useGetUserInfo = (state) => state.auth?.userInfo;
export const useGetIsLoading = (state) => state.auth?.isLoading;
export const useGetIsServerError = (state) => state.auth?.serverError;
export const useGetCommunities = (state) => state.auth?.userInfo?.communities || [];
export const useGetLoginErrorMessage = (state) => state.auth?.loginError;
export const useGetLoginErrorCreds = (state) => state.auth?.loginFailedCreds;
export const useGetSignUpErrorMessage = (state) => state.auth?.signUpError;
export const useGetSignUpSucessMessage = (state) => state.auth?.signSuccess;
export const useGetAuthLoading = (state) => state.auth?.authLoading;
export const useGetIfResetPasswordFail = (state) => state.auth?.resetPwdError;
export const useGetIfResetPasswordSuccess = (state) => state.auth?.resetPwdSuccess;
export const useGetCurrentCommunity = (state) => state.communityReducer?.communityDetail;
export const useGetHubspotSyncDetails = (state) => state.communityReducer?.hubspotSyncDetails;

export const useGetSearchFilters = (state) => state.communityReducer?.searchFilters || [];

export const useGetIntegrationChannelsConfig = (state) =>
  state.communityReducer?.integrationChannelConfigs || [];

export const useGetCommunityErrors = (state) => state.communityReducer?.communityErrors;
export const useGetNotificationsList = (state) => state.communityReducer?.notifications;
export const useGetNotificationsLoading = (state) => state.communityReducer?.notificationsLoading;
export const useGetNotificationsCount = (state) => state.communityReducer?.notificationsCount;
export const useGetUpcomingEvents = (state) => state.eventsReducer?.upcomingEvents;
export const useGetPastEvents = (state) => state.eventsReducer?.pastEvents;
export const useGetEventLoading = (state) => state.eventsReducer?.eventLoading;
export const useGetEventPreferencesId = (state) => state.eventsReducer?.preference;
export const useGetEventDataById = (state) => state.eventsReducer?.eventById;
export const useGetMembersLoading = (state) => state.membersReducer?.membersLoading;
export const useGetMembersData = (state) => state.membersReducer?.membersData;
export const useGetMembersDataFromId = (state) => state.membersReducer?.memberListFromId;
export const useGetUnsubscribeMemberList = (state) => state.membersReducer?.unsubscribeMemberList;
export const useGetV5Loading = (state) => state.membersReducer?.isV5Loading;
export const useGetMembersChartLoading = (state) => state.membersReducer?.membersChartLoading;
export const useGetMembersChartData = (state) => state.membersReducer?.membersChartContent;
export const useGetMembersSegmentsFilter = (state) => state.membersReducer?.segmentsFilter;
export const useGetMemberImportProcessing = (state) => state.membersReducer?.memberImportProcessing;
export const useGetMemberCustomFields = (state) => state.membersReducer?.memberCustomFields;
export const useGetMemberConfigureColumn = (state) => state.membersReducer?.memberConfigureColumn;
export const useGetIsDraftFilter = (state) => state.membersReducer?.isDraftFilter;
export const useGetMembersChannelsFilter = (state) => state.membersReducer?.channelsFilter;
export const useGetMembersRolesFilter = (state) => state.membersReducer?.memberRolesFilter;
export const useGetMembersSearchFilter = (state) => state.membersReducer?.memberSearchFilter;
export const useGetMemberToast = (state) => state.membersReducer?.membersToast;
export const useGetMemberDeleteError = (state) => state.membersReducer?.memberDeleteError;
export const useGetMembersRoles = (state) => state.membersReducer?.memberRoles;
export const useGetThreadsData = (state) => state.messagesReducer?.threadsData;
export const useGetThreadsLoading = (state) => state.messagesReducer?.threadsLoading;
export const useGetThreadsLoadingFromInfiniteScroll = (state) =>
  state.messagesReducer?.threadsLoadingFromInfiniteScroll;
export const useGetThreadReplyLoadingInfo = (state) =>
  state.messagesReducer?.threadReplyLoadingInfo;
export const useGetMessagesProviderChannelFilter = (state) =>
  state.messagesReducer?.providerChannelsFilter;
export const useGetThreadsNotification = (state) => state.messagesReducer?.threadsNotification;
export const useGetThreadsToShowCompletely = (state) =>
  state.messagesReducer?.threadsToShowCompletely;
export const useGetMessagesStatusFilter = (state) => state.messagesReducer?.statusFilter;
export const useGetMessagesChannelFilter = (state) => state.messagesReducer?.channelsFilter;
export const useGetActivitySummaryData = (state) => state.activityReducer?.activitySummary;
export const useGetActivityBannerYearReviewData = (state) =>
  state.activityReducer?.yearEndReviewBanner;
export const useGetActivityBannerYearReviewLoading = (state) =>
  state.activityReducer?.isYearEndReviewLoading;
export const useGetStaffData = (state) => state.staffReducer?.staffData;
export const useGetIfStaffLoading = (state) => state.staffReducer?.staffLoading;
export const useGetInvitedData = (state) => state.staffReducer?.invitedData;
export const useGetIfInvitedLoading = (state) => state.staffReducer?.invitedLoading;
export const useGetActivityProviderData = (state) => state.activityReducer?.activityProvider;
export const useGetActivityProviderChannelsData = (state) =>
  state.activityReducer?.activityChannels;
export const useGetSelectedActivityProviderChannel = (state) =>
  state.activityReducer?.selectedActivityChannel;
export const useGetActivityMessagesPostedData = (state) =>
  state.activityReducer?.activityMessagesPosted;
export const useGetActivityTopChannelsData = (state) => state.activityReducer?.activityTopChannels;
export const useGetActivityTopMembersData = (state) => state.activityReducer?.activityTopMembers;
export const useGetActivityTopMessagessData = (state) => state.activityReducer?.activityTopMessages;
export const useGetSelectedActivityMessageIntegration = (state) =>
  state.activityReducer?.selectedActivityMessageIntegration;
export const useGetIfActivityLoading = (state) => state.activityReducer?.activityLoading;
export const useGeActivityCurrentProvider = (state) => state.activityReducer?.currentProvider;
export const useGetActivitySummaryInsightsData = (state) =>
  state.activityReducer?.activitySummaryInsights;
export const useGetActivityHelpfulContributorsData = (state) =>
  state.activityReducer?.activityMembersHelpful;
export const useGetActivityMembersDiscussionData = (state) =>
  state.activityReducer?.activityMembersDiscussion;
export const useGetActivityMembersNewData = (state) => state.activityReducer?.activityMembersNew;

// Error hooks for Activity Summary
export const useGetActivitySummaryInsightsError = (state) =>
  state.activityReducer?.activitySummaryInsightsFetchFail;

export const useGetActivityMembersHelpfulError = (state) =>
  state.activityReducer?.activityMembersHelpfulFetchFail;

export const useGetActivityMembersDiscussionError = (state) =>
  state.activityReducer?.activityMembersDiscussionFetchFail;

export const useGetActivityMembersNewError = (state) =>
  state.activityReducer?.activityMembersNewFetchFail;

//Loader hooks for Actiivity members
export const useGetIfActivitySummaryInsightsLoading = (state) =>
  state.activityReducer?.activitySummaryInsightLoading;

export const useGetIfActivityMembersHelpfulLoading = (state) =>
  state.activityReducer?.activityMembersHelpfulLoading;

export const useGetIfActivityMembersDiscussionLoading = (state) =>
  state.activityReducer?.activityMembersDiscussionLoading;

export const useGetIfActivityMembersNewLoading = (state) =>
  state.activityReducer?.activityMembersNewLoading;

export const useGetIfActivityMembersTopMessageLoading = (state) =>
  state.activityReducer?.activityMembersTopMessagesLoading;

export const useGetResetPasswordLoading = (state) => state.auth?.resetPwdLoading;
export const useGetLandingPageConfig = (state) => state.landingPageReducer?.landingPageConfig;
// Top Discussion Hooks
export const useGetTopDiscussionData = (state) => state.weeklySummaryReducer?.topMessagesData;
export const useGetTopDiscussionDataLoading = (state) =>
  state.weeklySummaryReducer?.topMessagesLoading;
export const useGetTopDiscussionDataError = (state) => state.weeklySummaryReducer?.topMessagesError;
export const useGetTopDiscussionAddedToSummary = (state) =>
  state.weeklySummaryReducer?.selectedMessages;
export const useGetIsTopMessageLast = (state) => state.weeklySummaryReducer?.isTopMessageLast;
// Top Members Hooks
export const useGetTopMembersData = (state) => state.weeklySummaryReducer?.topMembersData;
export const useGetTopMembersLoading = (state) => state.weeklySummaryReducer?.topMembersLoading;
export const useGetTopMembersError = (state) => state.weeklySummaryReducer?.topMemberError;
export const useGetTopMembersAddedToSummary = (state) =>
  state.weeklySummaryReducer?.selectedMembers;
// Top Events Hooks
export const useGetTopEventData = (state) => state.weeklySummaryReducer?.topEventsData;
export const useGetTopEventLoading = (state) => state.weeklySummaryReducer?.topEventsLoading;
export const useGetTopEventError = (state) => state.weeklySummaryReducer?.topEventsError;
export const useGetTopEventsAddedToSummary = (state) => state.weeklySummaryReducer?.selectedEvents;
// Weekly summary-list
export const useGetWeeklySummaryList = (state) => state.weeklySummaryReducer?.weeklySummaryList;
export const useGetWeeklySummaryListLoading = (state) =>
  state.weeklySummaryReducer?.weeklySummaryLoading;
export const useGetWeeklySummaryListError = (state) =>
  state.weeklySummaryReducer?.weeklySummaryError;
// Integration Data Loading Banner
export const useGetIsDataLoadingBanner = (state) =>
  state.communityReducer?.isDataLoadingBannerVisible;
export const useGetAccountVerifiedStatus = (state) => state.auth?.accountVerified;
export const useGetAccountVerifiedMessage = (state) => state.auth?.accountVerificationMessage;

// Hooks for login page type

export const useGetLoginWithEmail = (state) => {
  console.log(state);
  return state.auth?.loginWithEmail;
};

export const useGetSignupUserInfoFromToken = (state) => state.auth?.signupUserInfoFromParamToken;
export const useGetIsSignupUserInfoFromTokenLoading = (state) => state.auth?.isSignupUserInfoFromTokenLoading;

// Rewards Center/Insight Hooks
export const useGetRewardConfigData = (state) => state.rewardReducer?.rewardConfig;
export const useGetRewardConfigLoading = (state) => state.rewardReducer?.isLoading;
export const useGetRewardToastData = (state) => state.rewardReducer?.rewardToast;
export const useGetRewardInsightData = (state) => state.rewardReducer?.insightMembers;
export const useGetRewardInsightSummary = (state) => state.rewardReducer?.insightSummary;
export const useGetRewardInsightLoading = (state) => state.rewardReducer?.isInsightLoading;
export const useGetRewardInsightToastData = (state) => state.rewardReducer?.insightToast;

// Dashboard Action Hooks
export const useGetCommunityAction = (state) => state.dashboardActionReducer?.communityAction;
export const useGetCommunityActionLoading = (state) =>
  state.dashboardActionReducer?.communityLoading;
export const useGetCommunityActionError = (state) => state.dashboardActionReducer?.communityError;
export const useGetCommunityActionCheck = (state) => {
  return transformActionCheck(state.dashboardActionReducer?.communityActionCheck);
};

export const useGetCommunityActionIdCheckMapping = (state) => {
  return getActionIdCheckMapping(
    state.dashboardActionReducer?.communityAction,
    state.dashboardActionReducer?.communityActionCheck,
  );
};

export const useGetCommunityActionCheckLoading = (state) =>
  state.dashboardActionReducer?.communityActionLoading;
export const useGetCommunityActionCheckError = (state) =>
  state.dashboardActionReducer?.communityActionError;

// Hooks for automation workflow
export const useGetAutomationSidebar = (state) => state.automationReducer?.isSidebarOpen;
export const useGetAutomationCurrentNode = (state) => state.automationReducer?.currentNode;
export const useGetAutomationFlowchart = (state) => state.automationReducer?.flowchart;
export const useGetAutomationSidebarAction = (state) => state.automationReducer?.sidebarAction;
export const useGetAutomationNewNodeType = (state) => state.automationReducer?.newNodeType;
export const useGetIsWorkflowLoading = (state) => state.automationReducer?.isWorkflowLoading;
export const useGetWorkflowList = (state) => state.automationReducer?.workflowList;
export const useGetWorkflowId = (state) => state.automationReducer?.workflowId;
export const useGetWorkflowTriggerEvent = (state) => state.automationReducer?.triggerEvent;
export const useGetWorkflowUpdatedTriggerEvent = (state) =>
  state.automationReducer?.updatedTriggerEvent;

// Subtype
export const useGetWorkflowUpdatedTriggerEventSubtype = (state) =>
  state.automationReducer?.updatedTriggerEventSubtype;
export const useGetWorkflowToaster = (state) => state.automationReducer?.toastMessage;
export const useGetWorkflowToastType = (state) => state.automationReducer?.toastType;
export const useGetWorkflowName = (state) => state.automationReducer?.workflowName;
export const useGetWorkflowDescription = (state) => state.automationReducer?.workflowDescription;
export const useGetAutomationCriteria = (state) => state.automationReducer?.entryCondition;
export const useGetAutomationCriteriaIsSet = (state) =>
  state.automationReducer?.isEntryConditionSet;
export const useGetIsSidebarOpen = (state) => state.automationReducer?.isSideMenuBarOpen;
export const useGetAutomationFirstNodeDescription = (state) =>
  state.automationReducer?.firstNodeDescription;

export const useGetAutomationIsMenuBarOpen = (state) =>
  state.automationReducer?.isAutomationMenuBarOpen;

export const useGetAutomationMenubarNode = (state) =>
  state.automationReducer?.automationMenubarNode;

export const useGetCurrentWorkflowStatus = (state) => state.automationReducer?.workflowStatus;

export const useGetWorkflowSurveyList = (state) => state.automationReducer?.workflowSurveyList;

export const useGetWorkflowStatusRow = (state) => state.automationReducer?.workflowListRowStatus;

export const useGetAutomationIsAnyNodeEdited = (state) => state.automationReducer?.isAnyNodeEdited;

export const useGetSurveyNameLocally = (state) => state.automationReducer?.surveyNameLocally;

export const useGetAutomationReportMode = (state) =>
  state.automationReducer?.isAutomationReportMode;

export const useGetAutomationBlockViewMode = (state) => state.automationReducer?.isBlockViewMode;

export const useGetAutomationReportNewNodeType = (state) =>
  state.automationReducer?.automationReportNewNodeType;

export const useGetAutomationReportCurrentNode = (state) =>
  state.automationReducer?.automationReportCurrentNode;

export const useGetAutomationReportByBlockId = (state) =>
  state.automationReducer?.automationReportByBlock;

export const useGetAutomationReportLoading = (state) =>
  state.automationReducer?.automationReportLoading;

export const useGetAutomationReportError = (state) =>
  state.automationReducer?.automationReportError;

export const useGetAutomationReportIsSidebarOpen = (state) =>
  state.automationReducer?.isAutomationReportSidebarOpen;

export const useGetAutomationReportBlockStatus = (state) =>
  state.automationReducer?.automationReportBlockStatus;

export const useGetAutomationReportBlockStatusLoading = (state) =>
  state.automationReducer?.automationReportBlockStatusLoading;

// Automation schedule congif local
export const useGetAutomationScheduleConfigLocalDayTime = (state) =>
  state.automationReducer?.automationScheduleConfigLocal;

// Automation internal slack channels
export const useGetInternalSlackChannelsList = (state) =>
  state.automationReducer?.slackInternalChannelList;

// Slack internal connect details
export const useGetSlackInternalConnectDetails = (state) =>
  state.companyReducer?.slackInternalDetails;

// Hooks for retention analytics
export const useGetRetentionLoading = (state) => state.retentionReducer?.retentionLoading;
export const useGetRetentionAnalyticsData = (state) => state.retentionReducer?.retentionRawData;
export const useGetRetentionCurrentProvider = (state) => state.retentionReducer?.currentProvider;
export const useGetRetentionError = (state) => state.retentionReducer?.retentionFetchFail;

//Hooks for engagement analytics
export const useGetEngagementLoading = (state) => state.engagementReducer?.engagementLoading;
export const useGetEngagementAnalyticsMembersData = (state) =>
  state.engagementReducer?.engagementMembersData;
export const useGetEngagementAnalyticsActionsData = (state) =>
  state.engagementReducer?.engagementActionsData;
export const useGetEngagementAnalyticsMembersDataMessages = (state) =>
  state.engagementReducer?.engagementMembersDataMessages;
export const useGetEngagementAnalyticsMembersDataReactions = (state) =>
  state.engagementReducer?.engagementMembersDataReactions;
export const useGetEngagementAnalyticsActionsDataMessages = (state) =>
  state.engagementReducer?.engagementActionsDataMessages;
export const useGetEngagementAnalyticsActionsDataReactions = (state) =>
  state.engagementReducer?.engagementActionsDataReactions;

export const useGetEngagementAnalyticsMembersDataFetchError = (state) =>
  state.engagementReducer?.engagementMembersDataFetchFail;

export const useGetEngagementAnalyticsActionsDataFetchError = (state) =>
  state.engagementReducer?.engagementActionsDataFetchFail;

export const useGeEngagementCurrentProvider = (state) => state.engagementReducer?.currentProvider;
export const useGetEngagementSelectedProviderChannels = (state) =>
  state.engagementReducer?.channelsFilter;
export const useGetEngagementProviderChannelsData = (state) =>
  state.engagementReducer?.engagementProviderChannels;
export const useGetEngagementMembersMetrics = (state) =>
  state.engagementReducer?.membersActionMetrics;
export const useGetEngagementActionsTakenMetrics = (state) =>
  state.engagementReducer?.actionsTakenMetrics;

// Hooks for member Segment Settings
export const useGetSegmentSettings = (state) => state.membersReducer?.segmentSettings;

// Hooks for member Segment Settings
export const useGetMemberSegmentTabCount = (state) => state.membersReducer?.segmentTabCount;

export const useGetSurveyList = (state) => state.membersReducer?.surveyList;

export const useGetTotalMemberCountHook = (state) => state.membersReducer?.totalMemberCount;

// Hooks for member Segment Settings
export const useGetConcurrentToken = (state) => state.globalReducer?.memberDataTable;

// Hooks for member Segment Settings
export const useGetmemberDataTableConcurrentToken = (state) => state.globalReducer?.memberDataTable;

// Hooks for Logging notifications
export const useGetLoggingNotificationsData = (state) =>
  state.notificationsReducer?.loggingNotificationsData;

export const useGetLoggingNotificationsDataError = (state) =>
  state.notificationsReducer?.loggingNotificationsDataFetchFail;

export const useGetLoggingNotificationsLoading = (state) =>
  state.notificationsReducer?.loggingNotificationsLoading;

export const useGetLoggingDetailedNotificationLoading = (state) =>
  state.notificationsReducer?.loggingNotificationDetailedLoading;

export const useGetNotificationSettings = (state) =>
  state.notificationsReducer?.notificationSettings;

export const useGetLogginDetailedNotificationData = (state) =>
  state.notificationsReducer?.loggingNotificationDetailedData;

export const useGetLogginDetailedNotificationChannelLoadData = (state) =>
  state.notificationsReducer?.loggingNotificationDetailedChannelLoadData;

// Hooks for discourse integration data
export const useGetDiscourseIntegrationSecret = (state) =>
  state.communityReducer?.discourseIntegrationSecret;

export const useGetDiscourseIntegrationUrl = (state) =>
  state.communityReducer?.discourseIntegrationUrl;

export const useGetDiscourseIntegrationLoading = (state) =>
  state.communityReducer?.discourseIntegrationLoading;

export const useGetCommuntyPlatformDetails = (state) => state.communityReducer?.communityPlatform;

export const useGetDataLoadNotification = (state) => state.communityReducer?.dataLoadNotification;

export const useGetIndivisualChannelUpdates = (state) => state.communityReducer?.channelUpdates;
export const useGetTotalMemberCount = (state) => state.communityReducer?.memberCount;
// Hooks for member Segment Settings
export const useGetMemberChannelList = (state) => state.membersReducer?.channelList;

export const useGetDefaultFilter = (state) => state.membersReducer?.defaultFilter;
export const useGetBulkDMMemberCount = (state) => state.membersReducer?.bulkMessageUserIdCount;

export const useGetBulkDMMemberCountLoading = (state) =>
  state.membersReducer?.bulkMessageUserIdCountLoading;

export const useGetMemberDataGridFilters = (state) => state.membersReducer?.memberDataGridFilter;

export const useGetSupportFeedFilters = (state) => state.messagesReducer?.supportFeedFilters;

export const useGetIsSupportDraftFilter = (state) => state.messagesReducer?.isSupportDraftFilter;

export const useGetSavedSupportFeedFilters = (state) => state.messagesReducer?.supportSavedFilters;

export const useGetSupportDefaultFilters = (state) => state.messagesReducer?.supportDefaultFilters;

export const useGetIsViewSavedSuccess = (state) => state.messagesReducer?.isViewSavedSuccess;

export const useGetMessageTopicsList = (state) => state.messagesReducer?.messageTopicsList;

export const useGetSelectedFilterName = (state) => state.messagesReducer?.selectedFilterName;

// Hooks for the Editor
export const useGetAllMentions = (state) => state.editorReducer?.allMentions;
export const useGetAllHashtags = (state) => state.editorReducer?.allHashtags;
export const useGetInitialMessage = (state) => state.editorReducer?.intialMessage;
export const useGetIsIntialPrefillRequired = (state) =>
  state.editorReducer?.isInitialPrefillRequired;

//Hook for automation hovered row
export const useGetHoveredRow = (state) => state.automationReducer?.hoveredRow;

export const useGetLastSyncTime = (state) => state.communityReducer?.lastSyncTime || [];

export const useGetWorkflowListDeletedRows = (state) =>
  state.automationReducer?.workflowListDeletedRows;

export const useGetDiscordOrganizationData = (state) => state.communityReducer?.discordOrganization;

export const useGetDiscordCustomBotData = (state) => state.communityReducer?.customBot || {};

export const useGetSlackCustomBotData = (state) => state.communityReducer?.slackCustomBot || {};

export const useGetCommunityDetails = (state) => state.communityReducer?.communityDetails || {};

export const useGetSignupCommunityDetails = (state) =>
  state.communityReducer?.signupCommunityDetails || {};

// Hooks for payments
export const useGetPaymentsModalOpen = (state) => state.paymentsReducer?.paymentsModalOpen;
export const useGetPaymentStatus = (state) => state.paymentsReducer?.paymentsPlanStatus;
export const useGetPaymentsStatusLoading = (state) =>
  state.paymentsReducer?.paymentsPlanStatusLoading;

export const useGetPaymentsPlanList = (state) => state.paymentsReducer?.paymentsPlanList;
export const useGetPaymentsPlanListLoading = (state) =>
  state.paymentsReducer?.paymentsPlanListLoading;

// Hooks for Support Bot payments
export const useGetSupportBotPaymentStatus = (state) =>
  state.paymentsReducer?.supportBotPaymentsPlanStatus;
export const useGetSupportBotPaymentsStatusLoading = (state) =>
  state.paymentsReducer?.supportBotPaymentsPlanStatusLoading;

export const useGetSupportBotPaymentsPlanList = (state) =>
  state.paymentsReducer?.supportBotPaymentsPlanList;
export const useGetSupportBotPaymentsPlanListLoading = (state) =>
  state.paymentsReducer?.supportBotPaymentsPlanListLoading;

export const useGetSupportBotCurrentUsage = (state) => state.paymentsReducer?.botCurrentUsage;
export const useGetSupportBotCurrentUsageLoading = (state) =>
  state.paymentsReducer?.botCurrentUsageIsLoading;

export const useGetUpgradeNowEventSource = (state) => state.paymentsReducer?.upgradeNowEventSource;

// Hooks for permission
export const useGetUserPermissions = (state) => state.permissionsReducer?.permissions;

// Hooks for Automation templates
export const useGetRecentAutomationsList = (state) =>
  state.automationTemplatesReducer?.recentAutomationsList;
export const useGetRecentAutomationsLoading = (state) =>
  state.automationTemplatesReducer?.recentAutomationsLoading;

export const useGetAutomationTemplatesCategoriesList = (state) =>
  state.automationTemplatesReducer?.automationTemplatesCategoriesList;
export const useGetAutomationTemplatesCategoriesListLoading = (state) =>
  state.automationTemplatesReducer?.automationTemplatesCategoriesListLoading;

export const useGetAutomationTemplatesByCategory = (state) =>
  state.automationTemplatesReducer?.automationTemplatesByCategory;
export const useGetAutomationTemplatesByCategoryLoading = (state) =>
  state.automationTemplatesReducer?.automationTemplatesByCategoryLoading;

export const useGetAutomationTemplateById = (state) =>
  state.automationTemplatesReducer?.automationTemplateById;
export const useGetAutomationTemplateByIdLoading = (state) =>
  state.automationTemplatesReducer?.automationTemplateByIdLoading;

export const useGetIsRenderFromAutomationTemplates = (state) =>
  state.automationTemplatesReducer?.isRenderFromAutomationTemplates;

export const useGetPlatformFilterTemplates = (state) =>
  state.automationTemplatesReducer?.templatesPlatformFilters;

export const useGetDateFilter = (state) => state.communityReducer?.dateFilter;

//Outreach Templates hooks
export const useGetRecentOutreachList = (state) =>
  state.outreachTemplatesReducer?.recentOutreachList;
export const useGetRecentOutreachLoading = (state) =>
  state.outreachTemplatesReducer?.recentOutreachLoading;

export const useGetOutreachList = (state) => state.outreachTemplatesReducer?.outreachList;
export const useGetOutreachLoading = (state) => state.outreachTemplatesReducer?.outreachLoading;
export const useGetOutreachTemplatesCategoriesList = (state) =>
  state.outreachTemplatesReducer?.outreachTemplatesCategoriesList;
export const useGetOutreachTemplatesCategoriesListLoading = (state) =>
  state.outreachTemplatesReducer?.outreachTemplatesCategoriesListLoading;

export const useGetOutreachTemplatesByCategoryPlatform = (state) =>
  state.outreachTemplatesReducer?.outreachTemplatesByCategoryPlatformList;
export const useGetOutreachTemplatesByCategoryPlatformLoading = (state) =>
  state.outreachTemplatesReducer?.outreachTemplatesByCategoryPlatformLoading;

export const useGetOutreachTemplateById = (state) =>
  state.outreachTemplatesReducer?.outreachTemplateById;
export const useGetOutreachTemplateByIdLoading = (state) =>
  state.outreachTemplatesReducer?.outreachTemplateByIdLoading;
export const useGetOutreachTemplateActive = (state) =>
  state.outreachTemplatesReducer?.isOutreachTemplateActive;

//Outreach reporting
export const useGeOutreachReportById = (state) => state.outreachReportReducer?.outreachReportById;
export const useGetOutreachReportByIdLoading = (state) =>
  state.outreachReportReducer?.outreachReportByIdLoading;

export const useGetOutreachReportMemberData = (state) =>
  state.outreachReportReducer?.outreachReportMemberData;
export const useGetOutreachReportMemberDataLoading = (state) =>
  state.outreachReportReducer?.outreachReportMemberDataLoading;

// Domain Verification
export const useGetDomainList = (state) => state.domainVerificationActionReducer?.domainList;
export const useGetDomainListLoading = (state) =>
  state.domainVerificationActionReducer?.domainListLoading;
export const useGetDomainDetails = (state) => state.domainVerificationActionReducer?.domainDetail;
export const useGetDomainDetailsLoading = (state) =>
  state.domainVerificationActionReducer?.domainDetailLoading;
export const useGetDomainEmailDetails = (state) =>
  state.domainVerificationActionReducer?.domainEmailDetail;
export const useGetDomainEmailDetailsLoading = (state) =>
  state.domainVerificationActionReducer?.domainEmailDetailLoading;

export const useGetOrgEmailList = (state) => state.domainVerificationActionReducer?.orgEmail;
export const useGetOrgEmailListLoading = (state) =>
  state.domainVerificationActionReducer?.orgEmailLoading;

export const useGetOutreachLoaderStatus = (state) => state.outreachReducer?.loaderStatus;
export const useGetOutreachNextRouter = (state) => state.outreachReducer?.nextRouter;
export const useGetActiveFeature = (state) => state.auth?.userInfo?.activeFeatures || [];

export const useGetYearEndReviewData = (state) => state?.yearEndReducer?.yearEndReviewData;

export const useGetYearEndReviewLoading = (state) => state?.yearEndReducer?.isYearEndReviewLoading;

// TOPIC
export const useGetTopicModel = (state) => state.rewardReducer?.topic;
export const useGetTopicModelLoading = (state) => state.rewardReducer?.topicLoading;
export const useGetTopicModelStatus = (state) => state.rewardReducer?.topicLoadingFailed;

export const useGetTopicUnigramModel = (state) => state.rewardReducer?.unigramTopic;
export const useGetTopicUnigramModelLoading = (state) => state.rewardReducer?.unigramTopicLoading;
export const useGetTopicUnigramModelStatus = (state) =>
  state.rewardReducer?.unigramTopicLoadingFailed;

export const useGetTopicBigramModel = (state) => state.rewardReducer?.bigramTopic;
export const useGetTopicBigramModelLoading = (state) => state.rewardReducer?.bigramTopicLoading;
export const useGetTopicBigramModelStatus = (state) =>
  state.rewardReducer?.bigramTopicLoadingFailed;

// HUBSPOT INTEGRATION
export const useGetHubspotRecieveConfig = (state) => state.hubspotReducer?.hubspotRecievedConfig;
export const useGetHubspotRecieveConfigLoading = (state) =>
  state.hubspotReducer?.hubspotRecieveLoading;
export const useGetHubspotRecieveConfigError = (state) => state.hubspotReducer?.hubspotRecieveError;
export const useGetHubspotDropdownData = (state) => state.hubspotReducer?.hubspotDropdownData;
export const useGetThreadoDropdownData = (state) => state.hubspotReducer?.threadoDropdownData;
export const useGetThreadoSendConfig = (state) => state.hubspotReducer?.hubspotSendConfig;
export const useGetThreadoCustomDropdownData = (state) =>
  state.hubspotReducer?.threadoCustomPropertyDropdownData;

export const useGetProviderFilterValue = (state) => state.analyticsSummaryReducer?.providerFilter;
export const useGetDateFilterValue = (state) => state.analyticsSummaryReducer?.dateFilter;

export const useGetMembersWidgetData = (state) => state.analyticsSummaryReducer?.membersWidgetData;

export const useGetActivitiesWidgetData = (state) =>
  state.analyticsSummaryReducer?.activitiesWidgetData;
export const useGetRecentAutomationsWidgetData = (state) =>
  state.analyticsSummaryReducer?.recentAutomationsWidgetData;

export const useGetRecentOutreachWidgetData = (state) =>
  state.analyticsSummaryReducer?.recentOutreachWidgetData;

export const useGetTopMembersWidgetData = (state) =>
  state.analyticsSummaryReducer?.topMembersWidgetData;

export const useGetTopMessagesWidgetData = (state) =>
  state.analyticsSummaryReducer?.topMessagesWidgetData;

export const useGetChannelInsightList = (state) => state.channelInsightReducer?.channelInsightList;
export const useGetChannelInsightListDetails = (state) =>
  state.channelInsightReducer?.channelInsightListDetails;

export const useGetChannelTrendingInsight = (state) =>
  state.channelInsightReducer?.channelTrendingInsight;

export const useGetTopicInsightList = (state) => state.topicInsightReducer?.topicInsightList;
export const useGetTopicInsightListDetails = (state) =>
  state.topicInsightReducer?.topicInsightListDetails;

export const useGetTopicTrendingInsight = (state) =>
  state.topicInsightReducer?.topicTrendingInsight;
// Graph reducer
export const useGetTrendActivityData = (state) => state.graphReducer?.chartData;
export const useGetActivityTopCard = (state) => state.graphReducer?.activityTopCardDetails;
export const useGetTrendSentimentData = (state) => state.graphReducer?.chartDataSentiment;
export const useGetSentimentTopCard = (state) => state.graphReducer?.sentimentTopCardDetails;
export const useGetGridType = (state) => state.graphReducer?.gridType;
export const useGetFrequencyType = (state) => state.graphReducer?.frequencyType;
export const useGetActivityType = (state) => state.graphReducer?.activityType;
export const useGetSentimentFrequency = (state) => state.graphReducer?.sentimentFrequency;
export const useGetResponsivenessType = (state) => state.graphReducer?.responsivenessType;

// Member analytics
export const useGetTrendNewMembersData = (state) => state.graphReducer?.chartDataNewMembers;
export const useGetTrendNewMembersDataLoading = (state) =>
  state.graphReducer?.chartLoadingNewMembers;
export const useGetTrendTotalMembersData = (state) => state.graphReducer?.chartDataTotalMembers;
export const useGetTrendTotalMembersDataLoading = (state) =>
  state.graphReducer?.chartLoadingTotalMembers;
export const useGetTotalMembersStaticInsight = (state) =>
  state.memberAnalyticsReducer?.totalMembersInsightStaticData;
export const useGetTotalMembersStaticInsightLoading = (state) =>
  state.memberAnalyticsReducer?.totalMembersStaticDataLoading;
export const useGetNewMembersStaticInsight = (state) =>
  state.memberAnalyticsReducer?.newMembersInsightStaticData;
export const useGetNewMembersStaticInsightLoading = (state) =>
  state.memberAnalyticsReducer?.newMemberStaticDataLoading;
export const useGetTopMembersStaticInsight = (state) =>
  state.memberAnalyticsReducer?.topMembersInsightStaticData;
export const useGetTopMembersStaticInsightLoading = (state) =>
  state.memberAnalyticsReducer?.topMembersInsightStaticDataLoading;
export const useGetTopMembersListData = (state) => state.memberAnalyticsReducer?.topMembersListData;
export const useGetTopMembersListDataLoading = (state) =>
  state.memberAnalyticsReducer?.topMembersListDataLoading;
export const useGetPotentialChampionsListData = (state) =>
  state.memberAnalyticsReducer?.potentialChampionsListData;
export const useGetPotentialChampionsListDataLoading = (state) =>
  state.memberAnalyticsReducer?.potentialChampionsListDataLoading;

// At Risk members
export const useGetAtRiskMembersData = (state) => state.memberAnalyticsReducer?.atRiskMembersData;
export const useGetAtRiskMembersDataLoading = (state) =>
  state.memberAnalyticsReducer?.atRiskMembersDataLoading;

// At Risk members
export const useGetTeamPerformanceData = (state) =>
  state.engagementInsightReducer?.engagementInsightList;
export const useGetTeamPerformanceDataDetails = (state) =>
  state.engagementInsightReducer?.engagementInsightListDetails;

export const useGetResponsivenessChartData = (state) =>
  state.engagementInsightReducer?.chartDataSentiment;

export const useGetResponsivenessInsightData = (state) =>
  state.engagementInsightReducer?.responsivenessInsightData;
export const useGetResponsivenessInsightDataLoading = (state) =>
  state.engagementInsightReducer?.responsivenessInsightDataLoading;
export const useGetRetentionInsightData = (state) =>
  state.retentionInsightReducer?.retentionInsightData;
export const useGetRetentionTrendData = (state) =>
  state.retentionInsightReducer?.chartDataSentiment;
// Sidebar
export const useGetSidebarToggleState = (state) => state.activityReducer?.sidebarToggleState;

// Chatbot
export const useGetDataSourceDetails = (state) => state?.chatbotReducer?.dataSourceDetails;
export const useGetDataSourceChildUrls = (state) => state?.chatbotReducer?.childURLs;
export const useGetDataSourceChildUrlsList = (state) => state?.chatbotReducer?.childUrlsList;
export const useGetDataSourceStatusDetails = (state) =>
  state?.chatbotReducer?.dataSourceStatusDetails;
export const useGetChatbotStatus = (state) => state?.chatbotReducer?.chatBotStatus;
export const useGetChatbotListenerData = (state) => state?.chatbotReducer?.chatbotListen;
export const useGetChatbotSettingsData = (state) => state?.chatbotReducer?.chatbotSettings;
export const useGetDataSourceDetailsLoading = (state) =>
  state?.chatbotReducer?.dataSourceDetailsLoading;
export const useGetSendTestBotMessageLoading = (state) =>
  state?.chatbotReducer?.testBotMessageLoading;
export const useGetBotProviderFilterValue = (state) => state.chatbotReducer?.botProviderFilter;
export const useGetBotStaticData = (state) => state.chatbotReducer?.botStaticData;
export const useGetDataSourceByIntegrationDetailsZendesk = (state) =>
  state?.chatbotReducer?.dataSourceZendeskDetails;
export const useGetDataSourceByIntegrationDetailsConfluence = (state) =>
  state?.chatbotReducer?.dataSourceConfluenceDetails;

export const useGetDataSourceByIntegrationDetailsNotion = (state) =>
  state?.chatbotReducer?.dataSourceNotionDetails;

export const useGetDataSourceByIntegrationDetailsFreshdesk = (state) =>
  state?.chatbotReducer?.dataSourceFreshdeskDetails;

export const useGetZendeskTicketsAssigneeList = (state) =>
  state?.chatbotReducer?.zendeskTicketsAssigneeList;

export const useGetZendeskTicketsTagsList = (state) =>
  state?.chatbotReducer?.zendeskTicketsTagsList;

export const useGetTrainTicketsFilter = (state) => state?.chatbotReducer?.trainTicketsDataFilter;

export const useGetFreshdeskTicketsAssigneeList = (state) =>
  state?.chatbotReducer?.freshdeskTicketsAssigneeList;

export const useGetFreshdeskTicketsTagsList = (state) =>
  state?.chatbotReducer?.freshdeskTicketsTagsList;

export const useGetFreshdeskTrainTicketsFilter = (state) =>
  state?.chatbotReducer?.freshdeskTrainTicketsDataFilter;

export const useGetZendeskTicketsGroupsList = (state) =>
  state?.chatbotReducer?.zendeskTicketsGroupsList;

export const useGetFreshdeskTicketsGroupsList = (state) =>
  state?.chatbotReducer?.freshdeskTicketsGroupsList;

export const useGetIntercomTicketsAssigneeList = (state) =>
  state?.chatbotReducer?.intercomTicketsAssigneeList;

export const useGetIntercomTicketsTagsList = (state) =>
  state?.chatbotReducer?.intercomTicketsTagsList;

export const useGetIntercomTrainTicketsFilter = (state) =>
  state?.chatbotReducer?.intercomTrainTicketsDataFilter;

export const useGetIntercomTicketsGroupsList = (state) =>
  state?.chatbotReducer?.intercomTicketsGroupsList;

export const useGetHubspotTicketsAssigneeList = (state) =>
  state?.chatbotReducer?.hubspotTicketsAssigneeList;

export const useGetHubspotTicketsTagsList = (state) =>
  state?.chatbotReducer?.hubspotTicketsTagsList;

export const useGetHubspotTrainTicketsFilter = (state) =>
  state?.chatbotReducer?.hubspotTrainTicketsDataFilter;

export const useGetHubspotTicketsGroupsList = (state) =>
  state?.chatbotReducer?.hubspotTicketsGroupsList;


export const useGetSalesforceTicketsAssigneeList = (state) =>
  state?.chatbotReducer?.salesforceTicketsAssigneeList;

export const useGetSalesforceTicketsTagsList = (state) =>
  state?.chatbotReducer?.salesforceTicketsTagsList;

export const useGetSalesforceTrainTicketsFilter = (state) =>
  state?.chatbotReducer?.salesforceTrainTicketsDataFilter;

export const useGetSalesforceTicketsGroupsList = (state) =>
  state?.chatbotReducer?.salesforceTicketsGroupsList;






export const useGetAddEditSidebarState = (state) => state?.chatbotReducer?.addEditSidebarOpenState;


export const useGetBotActivityData = (state) => state.chatbotReducer?.chartData;
export const useGetWebSnippetData = (state) => state.chatbotReducer.webSnippetData;

export const useGetQuestionPrivateSettings = (state) =>
  state?.chatbotReducer?.questionPrivateSettings;

// Chatbot customization
export const useGetBotCustomizationDetails = (state) =>
  state?.chatbotReducer?.dataCustomizationDetails;
export const useGetBotCustomizationDetailsLoading = (state) =>
  state?.chatbotReducer?.setDataCustomizationLoading;

export const useGetInstallChannelsList = (state) => state?.chatbotReducer?.installChannelsList;

// Analytics Slack Alert
export const useGetSidebarOpenState = (state) => state?.slackAlertAnalyticsReducer?.isSidebarOpen;
export const useGetSidebarTypeOfChange = (state) => state?.slackAlertAnalyticsReducer?.typeOfChange;
export const useGetSlackAlertDetails = (state) => state?.slackAlertAnalyticsReducer?.slackAlerts;
export const useGetSlackAlertByIdDetails = (state) =>
  state?.slackAlertAnalyticsReducer?.slackAlertObj;

// Agent assist
export const useGetDefaultTonesList = (state) => state?.agentAssistReducer?.defaultTonesList;
export const useGetAgentAssistResponseChecks = (state) =>
  state?.agentAssistReducer?.defaultResponseChecksList;
export const useGetCustomizeResponseChrome = (state) =>
  state?.agentAssistReducer?.customizeResponseChrome;
export const useGetCustomizeResponseSlack = (state) =>
  state?.agentAssistReducer?.customizeResponseSlack;
export const useGetAgentsListForInvite = (state) => state?.agentAssistReducer?.agentsListForInvite;
export const useGetInstallAgentsList = (state) => state?.agentAssistReducer?.invitedAgentsList;
export const useGetChromeInstallStatusNudge = (state) =>
  state?.agentAssistReducer?.chromeAppInstallStatusNudge;

export const useGetLanguagesList = (state) => state?.agentAssistReducer?.languagesList;

export const useGetLanguagesSettings = (state) => state?.agentAssistReducer?.languageSettings;

export const useGetBotResponseLengthSettings = (state) => state?.agentAssistReducer?.botResponseLengthSettings;

/**
 * Selector to get the status of new question availability from the ticket support reducer state.
 *
 * @param {object} state - The entire Redux store state.
 * @returns {boolean} - The status of whether a new question is available.
 */
export const useGetNewQuestionAvailable = (state) =>
  state.ticketSupportReducer?.isNewQuestionAvailable;

/**
 * Selector to get the loading status for fetching new question availability from the ticket support reducer state.
 *
 * @param {object} state - The entire Redux store state.
 * @returns {boolean} - The loading status for new question availability.
 */
export const useGetNewQuestionAvailableLoading = (state) =>
  state.ticketSupportReducer?.isNewQuestionAvailableLoading;

/**
 * Selector to get the failed status for fetching new question availability from the ticket support reducer state.
 *
 * @param {object} state - The entire Redux store state.
 * @returns {boolean} - The failed status for fetching new question availability.
 */
export const useGetNewQuestionAvailableFailed = (state) =>
  state.ticketSupportReducer?.isNewQuestionAvailableFailed;

/**
 * Selector to get the current reponse question object
 *
 * @param {object} state - The entire Redux store state.
 * @returns {boolean} - The failed status for fetching new question availability.
 */
export const useGetNewResponseObject = (state) =>
  state.ticketSupportReducer?.currentQuestionResponseObject;

/**
 * Hook for accessing the user's authentication status.
 * It returns the `isAuthenticated` state from the `authReducer`.
 *
 * @param {object} state - The entire Redux store state or context providing access to the state.
 * @returns {boolean} - The user's authentication status. `true` if the user is authenticated, otherwise `false`.
 */
export const useGetIsUserSuccessfullyLogin = (state) => state.authReducer?.isAuthenticated;

/**
 * @fileoverview Hooks for accessing the platform validation state and ticket identification number from the ticketSupportReducer.
 */

/**
 * Custom hook to determine if the platform validation process is currently loading.
 *
 * @param {object} state - The entire Redux store state.
 * @returns {boolean} - True if the platform validation process is currently loading, false otherwise.
 */
export const useGetIsPlatformValidatingLoading = (state) =>
  state.ticketSupportReducer?.isPlaftormValidationLoading;

/**
 * Custom hook to retrieve the ticket identification number from the ticketSupportReducer.
 *
 * @param {object} state - The entire Redux store state.
 * @returns {string|null} - The ticket identification number if available, null otherwise.
 */
export const useGetTicketIdentificationNumber = (state) => state.ticketSupportReducer?.ticketId;
export const useGetExternalTicketIdentificationNumber = (state) =>
  state.ticketSupportReducer?.externalTicketId;

/**
 * Custom hook to check if the platform is considered valid based on the ticket found in the ticketSupportReducer.
 *
 * @param {object} state - The entire Redux store state.
 * @returns {boolean} - True if a valid ticket is found and the platform is considered valid, false otherwise.
 */
export const useGetIsValidPlatform = (state) => state.ticketSupportReducer?.isTicketFound;

/**
 * Selector to check if the current platform is valid without authentication from the ticketSupportReducer state.
 * @param {object} state - The entire Redux state tree.
 * @returns {boolean} The validity status of the current platform from the ticketSupportReducer state.
 */
export const useGetCurrentPlatformIsValidNoAuth = (state) =>
  state.ticketSupportReducer?.isCurrentUrlValidPlatfrom;

/**
 * Selector to get refine and tone options from the ticketSupportReducer state.
 * @param {object} state - The entire Redux state tree.
 * @returns {object} The refine and tone options from the ticketSupportReducer state.
 */
export const useGetRefineAndToneOptions = (state) =>
  state.ticketSupportReducer?.refineAndToneOptions;

/**
 * Selector to get the loading status of the refine and tone options from the ticketSupportReducer state.
 * @param {object} state - The entire Redux state tree.
 * @returns {boolean} Loading status of the refine and tone options.
 */
export const useGetRefineAndToneOptionsLoading = (state) =>
  state.ticketSupportReducer?.refineAndToneOptionsLoading;

/**
 * Selector to get the loading status for the action ID related to refining from the ticketSupportReducer state.
 * @param {object} state - The entire Redux state tree.
 * @returns {boolean} Loading status of the action ID for refine.
 */
export const useGetActionIdForRefineLoading = (state) =>
  state.ticketSupportReducer?.isActionIdForRefineLoading;

/**
 * Selector to check if fetching the action ID for refining has failed in the ticketSupportReducer state.
 * @param {object} state - The entire Redux state tree.
 * @returns {boolean} Whether fetching the action ID for refining has failed.
 */
export const useGetActionIdForRefineFailed = (state) =>
  state.ticketSupportReducer?.isActionIdForRefineFailed;

/**
 * Selector to get the status of the action object for refining from the ticketSupportReducer state.
 * @param {object} state - The entire Redux state tree.
 * @returns {boolean} Status of the action object for refining.
 */
export const useGetisActionObjectForRefineObject = (state) =>
  state.ticketSupportReducer?.isActionObjectForRefineObject;

export const useGetUserTonePreference = (state) => state.ticketSupportReducer?.userTonePreferences;
export const useGetUserTonePreferenceLoading = (state) =>
  state.ticketSupportReducer?.userTonePreferencesLoading;

export const useGetPreviousAskChatHisoryMessages = (state) =>
  state.ticketSupportReducer?.previousChatMessages;

export const useGetFeedbackMessage = (state) => state.ticketSupportReducer?.feedBackMessage;

/**
 * Agent assist analytics
 */

export const useGetAgentAssistAnalyticsOverview = (state) =>
  state.agentAssistReducer?.analyticsOverview;
export const useGetAgentAssistAnalyticsEngagementTrend = (state) =>
  state.agentAssistReducer?.analyticsEngagementTrend;
export const useGetAgentAssistAnalyticsAgentsUsageList = (state) =>
  state.agentAssistReducer?.analyticsAgentList;
export const useGetAgentAssistAnalyticsBotResponseRate = (state) =>
  state.agentAssistReducer?.analyticsBotResponseRate;
export const useGetAgentAssistAnalyticsQueriesTrend = (state) =>
  state.agentAssistReducer?.analyticsQueriesTrend;

/**
 * AI model selection
 */

export const useGetAiModelSettings = (state) => state.communityReducer?.aiModelSettings;
export const useGetAiModelsList = (state) => state.communityReducer?.aiModelsList;

/**
 * Embedding AI model selection
 */

export const useGetEmbeddingAiModelSettings = (state) =>
  state.communityReducer?.embeddingAiModelSettings;
export const useGetEmbeddingAiModelsList = (state) => state.communityReducer?.embeddingAiModelsList;

/**
 * Ai resoning model selection
 */
export const useGetAiReasoningModelsList = (state) => state.communityReducer.aiReasoningModelsList;
export const useGetAiReasoningModelSettings = (state) => state.communityReducer?.aiReasoningModelSettings;
